<template>
  <div class="col">
    <server-table
      class="hidden-sm-only hidden-xs-only main_strategy_server_table"
      :count="$store.getters['strategies/total']"
      :DataItems="$store.getters['strategies/strategies']"
      @Refresh="Refresh"
      @export="Export"
      @Export_Bulk="Export_Bulk"
      @addBulk="add_bulk_dialog = true"
      :filter="filter"
      @reset="filter = {}"
      import_url="strategies/import"
      :loading="$store.getters['strategies/usersLoad']"
      :title="`${localization('Strategies')}`"
      :fileLoading="isLoading"
      :allow_bulk_process="true"
      admin_permission="admin_strategies"
      edit_permission="add_strategies"
    >
      <template #columns>
        <el-table-column 
          prop="level1" 
          :label="`${localization('Text')}`" 
          width="500"
        >
          <template slot-scope="scope">
            <div>
              {{ scope.row.level1 }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="classification"
          sortable
          :label="`${localization('Classification')}`"
        ></el-table-column>
        <el-table-column
          prop="pattern"
          sortable
          :label="`${localization('Pattern')}`"
        ></el-table-column>
        <el-table-column
          prop="age_group"
          sortable
          :label="`${localization('Age')}`"
        ></el-table-column>
        <el-table-column
          prop="topic_name"
          sortable
          :label="`${localization('Topic')}`"
        ></el-table-column>
        <el-table-column
          prop="priority"
          sortable
          :label="`${localization('Priority')}`"
        ></el-table-column>
        <el-table-column
          prop="Language"
          :label="`${localization('Language')}`"
          sortable
          width="120"
        >
          <template slot-scope="scope">
            <div >
              {{ language(languages, scope.row.language) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="removed"
          sortable
          :label="`${localization('State')}`"
        >
          <template slot-scope="scope">
            <l-button
              outline
              round
              size="sm"
              :type="scope.row.removed == 0 ? 'success' : 'danger'"
              style="font-size: 11px; margin: 1px; padding: 2px 7px"
            >
              {{
                scope.row.removed == 0
                  ? `${localization("Active")}`
                  : `${localization("Inactive")}`
              }} </l-button
            >&nbsp;
          </template>
        </el-table-column>
        <el-table-column prop="" label="_" width="100">
          <template slot-scope="scope">
            <div class="td-actions">
              <router-link
                v-if="
                  scope.row.parent_id == 0 && hasPermission('show_strategies')
                "
                :to="`/strategy/${scope.row.id}`"
                v-tooltip.top-center="`${localization('Show strategy')}`"
                class="btn btn-info btn-link btn-xs"
              >
                <i class="fa fa-eye"></i>
              </router-link>
              <a
                v-if="hasPermission('edit_strategies')"
                v-tooltip.top-center="`${localization('Edit strategy')}`"
                class="btn btn-warning btn-link btn-xs"
                @click="open_dialog_strategy(2, scope.row)"
              >
                <i class="fa fa-edit"></i>
              </a>
              <a
                v-if="hasPermission('edit_strategies')"
                v-tooltip.top-center="
                  scope.row.removed == 0
                    ? `${localization('Delete strategy')}`
                    : `${localization('Active strategy')}`
                "
                @click="remove(scope.row.id, scope.row.removed)"
                :class="
                  scope.row.removed == 0
                    ? `btn btn-danger btn-link btn-xs`
                    : `btn btn-success btn-link btn-xs`
                "
              >
                <i v-if="scope.row.removed == 0" class="fa fa-trash"></i>
                <i v-else class="fa fa-check"></i>
              </a>
            </div>
          </template>
        </el-table-column>
      </template>
      <template #filter>
        <el-row :gutter="10">
          <el-col :md="8">
            <el-select
              v-model="filter.classification"
              clearable
              :placeholder="`${localization('Classification')}`"
              class="mt-2 select-default w-100"
            >
              <el-option
                v-for="(item, index) in allClassification"
                :key="index"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-col>

          <el-col :md="8">
            <el-select
              v-model="filter.language"
              :placeholder="`${localization('Language')}`"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="(language, i) in languages"
                :key="i"
                :label="language.title"
                :value="language.id"
              ></el-option>
            </el-select>
          </el-col>

          <el-col :md="8">
            <el-select
              v-model="filter.pattern"
              clearable
              :placeholder="`${localization('Pattern')}`"
              class="mt-2 select-default w-100"
            >
              <el-option
                v-for="(item, index) in pattern"
                :key="index"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :md="6">
            <el-select
              v-model="filter.age_group"
              clearable
              :placeholder="`${localization('Age')}`"
              class="mt-2 select-default w-100"
            >
              <el-option
                v-for="(item, index) in ageGroup"
                :key="index"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :md="6">
            <el-select
              v-model="filter.priority"
              clearable
              :placeholder="`${localization('Priority')}`"
              class="mt-2 select-default w-100"
            >
              <el-option
                v-for="(item, index) in priority"
                :key="index"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :md="6">
            <el-select
              v-model="filter.type"
              clearable
              :placeholder="`${localization('Strategy Type')}`"
              class="mt-2 select-default w-100"
            >
              <el-option
                :label="`${localization('Admin Strategy')}`"
                :value="2"
              ></el-option>
              <el-option
                :label="`${localization('User Strategy')}`"
                :value="1"
              ></el-option>
            </el-select>
          </el-col>

          <el-col :md="6">
            <el-select
              v-model="filter.topic_id"
              clearable
              :placeholder="`${localization('Topic')}`"
              class="mt-2 select-default w-100"
            >
              <el-option
                v-for="(item, index) in topics"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
      </template>
      <template #action>
        <div @click="open_dialog_strategy(1, {})">
          <el-icon class="el-icon-plus"></el-icon>
          {{ localization("Add Your Own Strategy") }}
        </div>
      </template>
    </server-table>

    <server-table
      class="hidden-md-only hidden-lg-only hidden-xl-only"
      :count="$store.getters['strategies/total']"
      :DataItems="$store.getters['strategies/strategies']"
      @Refresh="Refresh"
      @export="Export"
      @Export_Bulk="Export_Bulk"
      @addBulk="add_bulk_dialog = true"
      :filter="filter"
      @reset="filter = {}"
      import_url="strategies/import"
      :loading="$store.getters['strategies/usersLoad']"
      :title="`${localization('Strategies')}`"
      :fileLoading="isLoading"
      :allow_bulk_process="true"
      admin_permission="admin_strategies"
      edit_permission="add_strategies"
    >
      <template #columns>
        <el-table-column prop="level1" :label="`${localization('Text')}`">
          <template slot-scope="scope">
            <div
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: normal;
                width: 100%;
                    word-break: break-word;
              "
              class="
                d-flex
                flex-column flex-sm-row
                justify-content-sm-between
                align-items-sm-center
              "
            >
              <p>{{ scope.row.level1 }}</p>
              <div class="d-flex justify-content-between">
                <router-link
                  v-if="
                    scope.row.parent_id == 0 && hasPermission('show_strategies')
                  "
                  :to="`/strategy/${scope.row.id}`"
                  v-tooltip.top-center="`${localization('Show strategy')}`"
                  class="btn btn-info btn-link btn-xs"
                >
                  <i class="fa fa-eye"></i>
                </router-link>
                <a
                  v-if="hasPermission('edit_strategies')"
                  v-tooltip.top-center="`${localization('Edit strategy')}`"
                  class="btn btn-warning btn-link btn-xs"
                  @click="open_dialog_strategy(2, scope.row)"
                >
                  <i class="fa fa-edit"></i>
                </a>
                <a
                  v-if="hasPermission('edit_strategies')"
                  v-tooltip.top-center="
                    scope.row.removed == 0
                      ? `${localization('Delete strategy')}`
                      : `${localization('Active strategy')}`
                  "
                  @click="remove(scope.row.id, scope.row.removed)"
                  :class="
                    scope.row.removed == 0
                      ? `btn btn-danger btn-link btn-xs`
                      : `btn btn-success btn-link btn-xs`
                  "
                >
                  <i v-if="scope.row.removed == 0" class="fa fa-trash"></i>
                  <i v-else class="fa fa-check"></i>
                </a>
              </div>
            </div>
          </template>
        </el-table-column>
      </template>
      <template #filter>
        <el-row :gutter="10">
          <el-col :md="8">
            <el-select
              v-model="filter.classification"
              clearable
              :placeholder="`${localization('Classification')}`"
              class="mt-2 select-default w-100"
            >
              <!-- v-for="(item, index) in allClassification" -->
              <el-option
                v-for="(item, index) in allClassification"
                :key="index"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-col>

          <el-col :md="8">
            <el-select
              v-model="filter.language"
              :placeholder="`${localization('Language')}`"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="(language, i) in languages"
                :key="i"
                :label="language.title"
                :value="language.id"
              ></el-option>
            </el-select>
          </el-col>

          <el-col :md="8">
            <el-select
              v-model="filter.pattern"
              clearable
              :placeholder="`${localization('Pattern')}`"
              class="mt-2 select-default w-100"
            >
              <el-option
                v-for="(item, index) in pattern"
                :key="index"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :md="8">
            <el-select
              v-model="filter.age_group"
              clearable
              :placeholder="`${localization('Age')}`"
              class="mt-2 select-default w-100"
            >
              <el-option
                v-for="(item, index) in ageGroup"
                :key="index"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :md="8">
            <el-select
              v-model="filter.priority"
              clearable
              :placeholder="`${localization('Priority')}`"
              class="mt-2 select-default w-100"
            >
              <el-option
                v-for="(item, index) in priority"
                :key="index"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :md="8">
            <el-select
              v-model="filter.type"
              clearable
              :placeholder="`${localization('Strategy Type')}`"
              class="mt-2 select-default w-100"
            >
              <el-option
                :label="`${localization('Admin Strategy')}`"
                :value="2"
              ></el-option>
              <el-option
                :label="`${localization('User Strategy')}`"
                :value="1"
              ></el-option>
            </el-select>
          </el-col>

          <el-col :md="24">
            <el-select
              v-model="filter.topic_id"
              clearable
              :placeholder="`${localization('Topic')}`"
              class="mt-2 select-default w-100"
            >
              <el-option
                v-for="(item, index) in topics"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
      </template>
      <template #action>
        <div @click="open_dialog_strategy(1, {})">
          <el-icon class="el-icon-plus"></el-icon>
          {{ localization("Add Your Own Strategy") }}
        </div>
      </template>
    </server-table>

    <el-dialog
      :title="
        type == 1
          ? `${localization('Add Your Own Strategy')}`
          : `${localization('Edit strategy')}`
      "
      :visible.sync="strategy_Dialog"
      top="4vh"
      width="70%"
      class="add_strategy_dialog_user"
    >
      <el-form
        :model="strategy"
        ref="ruleForm"
        :rules="rules"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :lg="12">
            <el-form-item :label="`${localization('Text')}`" prop="level1">
              <el-input v-model="strategy.level1" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12">
            <el-form-item label="Mode" class="mr-2" prop="mode">
              <el-select
                v-model="strategy.mode"
                clearable
                placeholder="Mode"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in modeTypes"
                  :key="index"
                  :label="item.mode"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- All Topics -->
          <el-col :lg="12" v-if="strategy.mode == 1">
            <el-form-item
              :label="`${localization('Topic')}`"
              class="mr-2"
              prop="topic_id"
            >
              <el-select
                v-model="strategy.topic_id"
                :placeholder="`${localization('Please select a topic')}`"
                style="width: 100%"
                @change="switchBetweenClassification(strategy.topic_id)"
              >
                <el-option
                  v-for="(item, index) in topics"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- At Work Topics -->
          <el-col :lg="12" v-if="strategy.mode == 2">
            <el-form-item
              :label="`${localization('Topic')}`"
              class="mr-2"
              prop="topic_id"
            >
              <el-select
                v-model="strategy.topic_id"
                :placeholder="`${localization('Please select a topic')}`"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in atWorkTopics"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- Career Topics -->
          <el-col :lg="12" v-if="strategy.mode == 3">
            <el-form-item
              :label="`${localization('Topic')}`"
              class="mr-2"
              prop="topic_id"
            >
              <el-select
                v-model="strategy.topic_id"
                :placeholder="`${localization('Please select a topic')}`"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in careerTopics"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <!-- v-if="strategy.topic_id != 86" -->
          <el-col :lg="12" v-if="strategy.topic_id != 86">
            <el-form-item
              :label="`${localization('Classification')}`"
              class="mr-2"
              prop="classification"
            >
              <el-select
                v-model="strategy.classification"
                :placeholder="
                  `${localization('Please select a classification')}`
                "
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in classification"
                  :key="index"
                  :label="item.label"
                  :value="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="12" v-if="strategy.topic_id == 86">
            <el-form-item
              :label="`${localization('Classification')}`"
              class="mr-2"
              prop="classification"
            >
              <el-select
                v-model="strategy.classification"
                :placeholder="
                  `${localization('Please select a classification')}`
                "
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in classification2"
                  :key="index"
                  :label="item.label"
                  :value="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :lg="12">
            <el-form-item
              :label="`${localization('Language')}`"
              class="mr-2"
              prop="language"
            >
              <el-select
                v-model="strategy.language"
                :placeholder="`${localization('Please select language')}`"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in languages"
                  :key="index"
                  :label="item.title"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!--
          <el-col :lg="12">
            <el-form-item label="Language" prop="language">
              <el-select
                v-model="strategy.language"
                placeholder="Please select a language"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in languages"
                  :key="index"
                  :label="item.title"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->

          <el-col :lg="12">
            <el-form-item :label="`${localization('Pattern')}`" prop="pattern">
              <el-select
                v-model="strategy.pattern"
                :placeholder="`${localization('Please select a pattern')}`"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in pattern"
                  :key="index"
                  :label="item.label"
                  :value="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="12">
            <el-form-item
              :label="`${localization('Age Group')}`"
              class="mr-2"
              prop="age_group"
            >
              <el-select
                v-model="strategy.age_group"
                :placeholder="`${localization('Please select Age group')}`"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in ageGroup"
                  :key="index"
                  :label="item.label"
                  :value="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="12">
            <el-form-item
              :label="`${localization('Priority')}`"
              prop="priority"
            >
              <el-input-number
                v-model="strategy.priority"
                :min="1"
                :max="12"
                style="width: 100%"
              ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span
        slot="footer"
        class="
          dialog-footer
          user_profile_dialog_footer
        "
      >
        <el-button
          class="text-center text-capitalize"
          @click="strategy_Dialog = false"
          >{{ localization("Cancel") }}</el-button
        >
        <el-button
          type="primary"
          class="text-center text-capitalize"
          @click="save"
          >{{ localization("Add Your Own Strategy") }}</el-button
        >
      </span>
    </el-dialog>

    <!-- //? Add bulk Dialog  -->
    <el-dialog
      :title="`${localization('Add Bulk')}`"
      :visible.sync="add_bulk_dialog"
      top="4vh"
      width="60%"
      :close-on-click-modal="false"
      class="add_strategy_dialog_user"
    >
      <!-- //? Language  -->
      <el-form
        :model="addBulkModel"
        ref="bulkRuleForm"
        v-loading="isLoading"
        :rules="bulkRules"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col
            ><el-form-item
              :label="`${localization('Language')}`"
              prop="language"
            >
              <el-select
                v-model="addBulkModel.language"
                :placeholder="`${localization('Please select language')}`"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in languages"
                  :key="index"
                  :label="item.title"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" type="flex" class="row-bg" justify="end">
          <el-col :lg="12">
            <el-form-item class="w-100">
              <el-upload
                class="w-100"
                style="
                  display: grid;
                  grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
                  grid-gap: 1rem;
                "
                :auto-upload="false"
                :file-list="fileList"
                :on-change="handleUploadChange"
                :on-success="Refresh"
                accept=".xlsx"
                :before-upload="beforeUpload"
                :action="import_src_bulk"
                :data="{ language: addBulkModel.language }"
                :disabled="!addBulkModel.language"
                ref="upload"
              >
                <el-button
                  @click="validateFileUpload"
                  slot="trigger"
                  class="w-100"
                  type="primary"
                  >{{ localization("Select file") }}</el-button
                >
              </el-upload>
            </el-form-item></el-col
          >
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer user_profile_dialog_footer">
        <el-button
          class="text-center text-capitalize"
          @click="add_bulk_dialog = false"
          >{{ localization("Cancel") }}</el-button
        >
        <el-button
          type="primary"
          class="text-center text-capitalize"
          @click="submitUpload"
          :disabled="isLoading"
          >{{ localization("Submit") }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ServerTable from "../../components/DataTablePagination.vue";

import "element-ui/lib/theme-chalk/display.css";
import Swal from "sweetalert2";

export default {
  name: "index",
  components: { ServerTable },
  data() {
    return {
      strategy_Dialog: false,
      type: null,
      languages: [],
      rules: {
        level1: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        classification: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ],
        topic_id: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ],
        language: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ],
        pattern: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ],
        age_group: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ],
        priority: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ]
      },
      ageGroup: [
        { label: "Adult" },
        { label: "Child" },
        { label: "Youth" },
        { label: "ABE" }
      ],
      classification: [
        { label: "Forge" },
        { label: "Intensify" },
        { label: "Tether" },
        { label: "Use First" }
      ],
      classification2: [
        { label: "UF0AN0" },
        { label: "UF0AN100" },
        { label: "UF100" },
        { label: "UF20AN0" },
        { label: "UF40AN0" },
        { label: "UF66AN0" },
        { label: "UF40AN20" },
        { label: "UF20AN66" },
        { label: "UF40AN33" },
        { label: "UF75AN20" },
        { label: "UF0AN20" },
        { label: "UF0AN50" },
        { label: "UF20AN20" },
        { label: "UF60AN20" }
      ],
      allClassification: [
        { label: "Forge" },
        { label: "Intensify" },
        { label: "Tether" },
        { label: "Use First" },
        { label: "Avoid" },
        { label: "UF0AN0" },
        { label: "UF0AN100" },
        { label: "UF100" },
        { label: "UF20AN0" },
        { label: "UF40AN0" },
        { label: "UF66AN0" },
        { label: "UF40AN20" },
        { label: "UF20AN66" },
        { label: "UF40AN33" },
        { label: "UF75AN20" },
        { label: "UF0AN20" },
        { label: "UF0AN50" },
        { label: "UF20AN20" },
        { label: "UF60AN20" }
      ],
      pattern: [
        { label: "Confluence" },
        { label: "Precision" },
        { label: "Sequence" },
        { label: "Technical Reasoning" }
      ],
      priority: [
        { label: 1 },
        { label: 2 },
        { label: 3 },
        { label: 4 },
        { label: 5 },
        { label: 6 },
        { label: 7 },
        { label: 8 },
        { label: 9 },
        { label: 10 },
        { label: 11 },
        { label: 12 }
      ],
      strategy: {},
      topics: [],
      filter: {},
      localSort: { col: "id", order: "DESC" },
      // preserve sorting concept to local component

      add_bulk_dialog: false,
      addBulkModel: {},
      isLoading: false,
      fileList: [],
      bulkRules: {
        language: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ]
      },
      testAllClass: [],
      modeTypes: [
        {
          id: 1,
          mode: "In School"
        },
        {
          id: 2,
          mode: "At Work"
        },
        {
          id: 3,
          mode: "Career Match"
        }
      ]
    };
  },
  computed: {
    import_src_bulk() {
      return `${process.env.VUE_APP_BASE_URL}/strategies-language/import`;
    },
    atWorkTopics() {
      return this.$store.getters["strategies/getAtWorkTopics"];
    },
    careerTopics() {
      return this.$store.getters["strategies/getCareerTopics"];
    }
    // checkIfTeamTopicOrNot() {
    //   let topicId = this.strategy.topic_id;
    //   if (topicId) {
    //   }
    // }
    //
  },
  methods: {
    Refresh(query) {
      // stop loading after importing bulk
      this.isLoading = false;
      // close add bulk dialog if opened
      if (this.add_bulk_dialog) this.add_bulk_dialog = false;
      // empty file list ofr bulk upload
      this.fileList = [];
      this.$store
        .dispatch("strategies/Getstrategies", { query: query })
        .then(_ => {
          this.topics = this.$store.getters["strategies/topics"];
          this.languages = this.$store.getters["strategies/languages"];
        });
    },

    save() {
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          if (this.type == 1) {
            this.$store
              .dispatch("strategies/AddStrategies", { query: this.strategy })
              .then(_ => {
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: "Strategy has been added.",
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
                this.Refresh({});
                this.strategy_Dialog = false;
              });
          } else {
            this.$store
              .dispatch("strategies/UpdateStrategies", {
                id: this.strategy.id,
                query: this.strategy
              })
              .then(_ => {
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: "Strategy has been updated.",
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
                this.Refresh({
                  limit: 4,
                  offset: 0,
                  order_by: this.localSort.col,
                  order_direction: this.localSort.order
                });
                this.strategy_Dialog = false;
              });
          }
        } else {
          return false;
        }
      });
    },
    remove(id, removed) {
      Swal.fire({
        title: "",
        text:
          removed == 0
            ? `Are you sure to delete this strategy?`
            : "Are you sure to Active this strategy?",
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText: "Confirm",
        buttonsStyling: false
      }).then(date => {
        if (date.isConfirmed) {
          this.$store.dispatch("strategies/delete", id).then(_ => {
            this.Refresh(null);
            Swal.fire({
              title: removed == 0 ? `` : "",
              text:
                removed == 0
                  ? "Strategy has been deleted."
                  : "Strategy has been actived.",
              icon: "success",
              confirmButtonClass: "btn btn-success",
              buttonsStyling: false
            });
          });
        }
      });
    },

    open_dialog_strategy(type, value) {
      delete value.topic_name;
      delete value.user;
      this.strategy = { ...value };
      this.strategy_Dialog = true;
      this.type = type;
    },
    Export(query) {
      this.$store.dispatch("strategies/Export", { query: query });
    },
    Export_Bulk() {
      this.$store.dispatch("strategies/ExportBulk");
    },
    beforeUpload(file) {
      // start loading before uploading
      this.isLoading = true;
    },
    handleUploadChange(file, fileList) {
      this.fileList = fileList.slice(-1);
    },
    validateFileUpload() {
      this.$refs["bulkRuleForm"].validate(valid => {
        if (valid) {
          return true;
        } else {
          return false;
        }
      });
    },
    submitUpload() {
      this.$refs["bulkRuleForm"].validate(valid => {
        if (valid) {
          this.$refs.upload.submit();
          Swal.fire({
            title: "",
            icon: "success",
            text: "Bulk has been added.",
            type: "success",
            confirmButtonClass: "btn btn-success ",
            buttonsStyling: false
          });
        } else {
          return false;
        }
      });
    },

    language(languages, id) {
      let language = languages.filter(el => el.id == id)[0];
      if (language) {
        return language.title;
      }
      return "-";
    },
    // whatClassNow(topicId){
    //   if (topicId == null) {
    //     this.strategy.topic_id == null
    //   } else if(topicId) {

    //   }
    // },
    switchBetweenClassification(id) {
      if (id == 86) {
        this.classification.forEach(element => {
          if (this.strategy.classification == element.label) {
            this.strategy.classification = "";
          }
        });

        this.testAllClass = this.classification2;
      } else {
        this.testAllClass = this.classification;
        this.classification2.forEach(element => {
          if (this.strategy.classification == element.label) {
            this.strategy.classification = "";
          }
        });
      }
    }
  }
  // watch: {
  // this.topics
  //   handlerTopicTypes(newVal) {
  //     if (this.strategy.topic_id != 86) {
  //     }
  //   }
  // }
};
</script>

<style scoped lang="scss">
::v-deep .el-upload {
  width: 100%;
}
::v-deep
  .el-form-item.is-required:not(.is-no-asterisk)
  > .el-form-item__label:before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}

::v-deep .el-form-item__label::before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}
.user_profile_dialog_footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  @media (min-width: 320px) and (max-width: 767px) {
    justify-content: center;
  }
}
</style>

<style lang="scss">
.add_strategy_dialog_user {
  .el-dialog {
    @media (min-width: 320px) and (max-width: 767px) {
      width: 90% !important;
    }
  }
}
.main_strategy_server_table {
  .el-table .cell {
    word-break: break-word;
  }
}
</style>
